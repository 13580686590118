import { FunctionComponent } from 'react';
import { Box, Button, CircularProgress, SxProps, Theme } from '@mui/material';

import { ButtonVariant } from '../../styles/buttons';

interface FormButtonProps {
  caption: string;
  onClick?: () => void; // @todo replace by MouseEvent<HTMLInputElement>
  loading?: boolean;
  disabled?: boolean;
  marginTop?: number;
  /**
   * The URL to link to when the Next button is clicked.
   *  If defined, an `a` element will be used as the root node.
   */
  nextHref?: string;
  dataCy?: string;
  buttonSx?: SxProps<Theme> | undefined;
  variant?: ButtonVariant;
}

const FormButton: FunctionComponent<FormButtonProps> = ({
  loading = false,
  disabled = false,
  onClick,
  caption,
  marginTop = 5,
  nextHref,
  buttonSx,
  dataCy = '',
  variant = 'contained',
}) => (
  <Box mt={marginTop}>
    <Button
      color='primary'
      data-cy={dataCy}
      disabled={disabled || loading}
      fullWidth
      href={nextHref}
      onClick={onClick}
      sx={buttonSx}
      variant={variant}
    >
      {loading && <CircularProgress size={24} />}
      {caption}
    </Button>
  </Box>
);

export default FormButton;
