import { FunctionComponent, ReactElement } from 'react';
import { Box } from '@mui/material';

import StickyFooterLayout from '../layout/StickyFooterLayout';

import LoginHeader from './LoginHeader';
import Logo from './Logo';

const LandingTemplate: FunctionComponent = ({
  children,
}) => (
  <StickyFooterLayout HeaderElement={<LoginHeader />}>
    <Box mt={2} textAlign='center'>
      <Logo size='md' />
    </Box>
    <Box display={'flex'} flexDirection='column' my={5} textAlign='center'>
      {children}
    </Box>
  </StickyFooterLayout>
);

export const getLandingTemplate = (page: ReactElement): ReactElement => (
  <LandingTemplate>{page}</LandingTemplate>
);
