import { FunctionComponent, ReactElement } from 'react';
import { Grid } from '@mui/material';
import withStyles from '@mui/styles/withStyles';

import { ComponentWithStyles } from '../../types/ComponentWithStyles';

import Footer from './Footer';

type StickyFooterLayoutProps = {
  HeaderElement: ReactElement | null;
  align?: 'center' | 'left';
  bgcolor?: string;
  ml?: number;
};

const StickyFooterLayout: FunctionComponent<
  StickyFooterLayoutProps & ComponentWithStyles
> = ({ children, classes, HeaderElement, ml = 0 }) => (
  <Grid className={classes.root} container direction='column'>
    <Grid component='header' item ml={ml}>
      {HeaderElement}
    </Grid>
    <Grid
      className={classes.main}
      component='main'
      container
      direction='column'
      item
      justifyContent='center'
      ml={ml}
    >
      <>{children}</>
    </Grid>
    <Grid className={classes.footer} component='footer' item ml={ml}>
      <Footer />
    </Grid>
  </Grid>
);

export default withStyles((theme) => ({
  root: {
    minHeight: '100%',
  },
  main: {
    flexGrow: 1,
    padding: `0 ${theme.spacing(4)}`,
  },
  footer: {
    backgroundColor: theme.palette.primary.main,
  },
}))(StickyFooterLayout);
