import { FunctionComponent } from 'react';
import { Grid, Link, Typography } from '@mui/material';

import { BRAND_COMPANY_NAME } from '../../constants/branding';
import routes from '../../lib/routes';

const LoginHeader: FunctionComponent = () => {
  return (
    <Grid container justifyContent={'end'} pr={5} pt={5}>
      <Grid item>
        <Typography
          mr={1}
          style={{ color: 'textSecondary', overflow: '999' }}
          variant='subtitle2'
        >
          {`Have a ${BRAND_COMPANY_NAME} account?`}
        </Typography>
      </Grid>
      <Grid item>
        <Link
          color='primary'
          data-cy='login-button'
          href={routes.internal.accountLogin.url}
        >
          Login
        </Link>
      </Grid>
    </Grid>
  );
};

export default LoginHeader;

