import { DetailedHTMLProps, FormHTMLAttributes, FunctionComponent } from 'react';
import { useRouter } from 'next/router';
import { Box, Grid, Typography } from '@mui/material';

import FormFooter from '../typography/FormFooter';

import FormButton from './FormButton';

interface FormBoxProps {
  backHidden?: boolean;
  /**
   * The URL to link to when the Back button is clicked.
   *  If defined, an `a` element will be used as the root node.
   */
  backHref?: string;
  footer?: string | JSX.Element;
  nextHidden?: boolean;
  backCaption?: string;
  nextCaption?: string;
  nextDisabled?: boolean;
  /**
   * The URL to link to when the Next button is clicked.
   *  If defined, an `a` element will be used as the root node.
   */
  nextHref?: string;
  onNextClick?: () => unknown | Promise<unknown>;
  subtitle?: string;
  title?: string;
  formProps?: DetailedHTMLProps<FormHTMLAttributes<HTMLFormElement>, HTMLFormElement>;
  buttonLoading?: boolean;
  dataCy?: string;
  maxWidth?: number;
}

const FormBox: FunctionComponent<FormBoxProps> = ({
  backHidden = false,
  backHref,
  children,
  footer,
  nextHidden = false,
  backCaption = 'Back',
  nextCaption = 'Continue',
  nextDisabled = false,
  nextHref,
  onNextClick,
  subtitle,
  title,
  formProps,
  buttonLoading = false,
  dataCy = '',
  maxWidth = 412,
}) => {
  const router = useRouter();

  const handleNextClick = async () => {
    if (typeof onNextClick === 'function')
      await onNextClick();
    if (nextHref)
      router.push(nextHref);
  };

  return (
    <div data-cy={dataCy}>
      {
        title && (
          <Typography variant='h1'>
            {title}
          </Typography>
        )
      }
      {
        subtitle && (
          <Typography variant='subtitle1'>
            {subtitle}
          </Typography>
        )
      }
      <Box
        maxWidth={maxWidth}
        mt={5}
        mx='auto'
        textAlign='left'
      >
        <form {...formProps}>
          {children}
        </form>
        <Box mt={5}>
          <Grid container spacing={2}>
            {
              !backHidden
                ? (
                  <Grid item xs={6}>
                    <FormButton
                      caption={backCaption}
                      nextHref={backHref}
                      variant={'outlined'}
                    >
                    </FormButton>
                  </Grid>
                )
                : (
                  <Grid item xs={3}>&nbsp;</Grid>
                )
            }
            {
              !nextHidden && (
                <Grid item sm={6} xs={backHidden ? 12 : 6}>
                  <FormButton
                    buttonSx={{ marginBottom: 8 }}
                    caption={nextCaption}
                    dataCy={dataCy + '-button'}
                    disabled={nextDisabled}
                    loading={buttonLoading}
                    nextHref={nextHref}
                    onClick={handleNextClick}
                  >
                  </FormButton>
                </Grid>
              )
            }
          </Grid>
        </Box>
        {
          !!footer && (
            <FormFooter>
              {footer}
            </FormFooter>
          )
        }
      </Box>
    </div>
  );
};

export default FormBox;
